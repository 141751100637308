/* main background */
.background-img {
  background-image: url("./images/AstroRy-revamp.png");
  background-repeat: no-repeat;
  background-position: left 0px bottom -10px;
  background-size: contain;
  height: 300px;
  width: 300px;
  position: relative;
}

.background-img span {
  position: absolute;
  top: 65px;
  left: 47px;
  width: 110px;
  text-align: center;
  font-size: 1.3em;
  background-color: #000000af;
  border: ridge azure 3px;
  border-radius: 20%;
}

/* navigation menu logo */
.nav-logo {
  background: url("./images/rocketRy.png") no-repeat center center;
  background-size: 90% 100%;
  width: 50px;
  height: 60px;
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px;
  transition: background-size 0.5s ease-in-out;
}

.nav-logo:hover {
  background: url("./images/rocketRy.png") no-repeat center center;
  background-size: 105% 110%;
  width: 50px;
  height: 60px;
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px;
}

/* header */
.header-planet {
  background: url("./images/earth.jpeg") center center;
  background-size: contain;
  width: 370px;
  height: 370px;
  position: relative;
  background-color: transparent;
  border-radius: 50% 50% 50% 50%;
}

/* projects */
.freelance-img {
  display: flex;
  max-width: 100%;
  border-top: #abd5ff39 1px solid;
  border-bottom: #abd5ff39 1px solid;
  border-left: transparent;
  border-right: transparent;
  margin-top: 2px;
}

.bootcamp-img {
  display: flex;
  border: #abd5ff80 1px solid;
  border-radius: 2%;
  min-width: 100%;
  max-width: 100%;
}

/* resume */
.resume-img {
  background: url("../assets/images/portfolioResumePic.png") no-repeat center
    center;
  background-size: cover;
  width: 300px;
  min-height: 400px;
  margin-top: 3% auto;
  border: #abd5ffc5 1px solid;
  border-radius: 2%;
  box-shadow: 0px -3px 20px 2px #abd5ff60;
}

@media screen and (max-width: 877px) {
  .bootcamp-img {
    border-right: transparent;
    border-left: transparent;
  }
}
