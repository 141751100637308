/* import font from google fonts */
@import url("https://fonts.googleapis.com/css2?family=Nabla&family=Space+Grotesk:wght@300;600&display=swap");

/* GENERAL/GLOBAL */
/* GENERAL/GLOBAL */
/* GENERAL/GLOBAL */
/* GENERAL/GLOBAL */
/* GENERAL/GLOBAL */

* {
  font-family: "Space Grotesk", "Courier New", Courier, monospace !important; /* applies google font to whole site */
}

html,
body {
  background-color: #131313;
}

#root {
  background-color: #131313;
  color: #f0ffff;
}

/* COMPONENTS */
/* COMPONENTS */
/* COMPONENTS */
/* COMPONENTS */
/* COMPONENTS */

/* NAV */
/* NAV */
/* NAV */

/* HEADER */
/* HEADER */
/* HEADER */

/* NOTE: .header-planet can be found in imageSource.css */

.header-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.header-textbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: 5% 20% 20%;
  text-align: center;
  color: #f0ffff;
  text-shadow: 1px 2px #000000;
}

#pageTitle {
  width: 0;
  height: 0;
}

/* FOOTER */
/* FOOTER */
/* FOOTER */

.footer-name {
  margin: auto;
  margin-right: 0;
  margin-left: 0;
  position: relative;
  font-size: x-large;
  text-align: center;
  width: 50%;
}

/* UTILITIES */
/* UTILITIES */
/* UTILITIES */

.hide-overflow {
  overflow: hidden;
}

.increase-z {
  position: relative;
  z-index: 10;
}

.add50Px {
  height: 3000px;
  width: 100%;
}

/* PAGES */
/* PAGES */
/* PAGES */
/* PAGES */
/* PAGES */

/* CONTACT MODAL */
/* CONTACT MODAL */

.contact-form {
  text-align: center;
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 1%;
}

/* ABOUT */
/* ABOUT */
.page-intro {
  text-align: center;
  padding: 2%;
  margin: 2%;
  height: 75vh;
}

.section-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin: 2% auto;
  padding: 5%;
  min-height: 75vh;
  width: 100%;
}

.section-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-end;
  text-align: right;
  min-width: 150px;
  max-width: 175px;
  padding-top: 5%;
}

.section-title h2 {
  margin: 2%;
  margin-right: 4%;
  padding: 2%;
}

.section-content {
  display: flex;
  flex-direction: column;
  margin: 2%;
  padding: 2%;
  min-width: 50%;
  max-width: 70%;
  border-top: 1px solid #abd5ffc5;
  border-left: #abd5ffc5 1px solid;
  background: linear-gradient(0deg, transparent 0%, #ffffff20 100%);
}

.section-content h3 {
  border-left: #5d90c3 2px solid;
  padding: 2%;
}

.section-content ul {
  list-style-type: none;
  padding-top: 0;
  margin-top: 0;
  text-align: start;
}

/* PROJECTS */
/* PROJECTS */

.projects-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin: 2% auto;
  padding: 5%;
  min-height: 75vh;
  width: 100%;
}

.projects-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-end;
  text-align: right;
  min-width: 150px;
  max-width: 175px;
  padding-right: 2%;
}

.projects-title h2 {
  margin: 2%;
  padding: 2%;
}

.projects-title h4 {
  padding: 2%;
  margin: 2%;
}

.projects-content {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  max-width: 70%;
  border-left: #abd5ffc5 1px solid;
}

.projects-aside {
  display: flex;
  flex-direction: column;
  margin-bottom: 3%;
  background: linear-gradient(0deg, transparent 0%, #ffffff20 50%);
  border-radius: 0 10px 0 0;
}

/* freelance */

.freelance-title {
  padding-top: 3%;
  padding-left: 2%;
  padding-bottom: 1%;
  text-align: center;
  text-shadow: 1px 2px #000000;
  border-top: 1px solid #abd5ffc5;
  /* border-bottom: #39585840 2px solid; */
  border-radius: 0 10px 0 10px;
}

.freelance-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
}

/* .freelance-image styling found in imageSource.css */
/* .freelance-image styling found in imageSource.css */
/* .freelance-image styling found in imageSource.css */

.freelance-description {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 90%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.freelance-description span {
  text-align: center;
  padding-right: 5%;
  min-width: 250px;
  max-width: 50%;
}

.freelance-description ul {
  list-style-type: none;
  text-align: start;
  padding-left: 3%;
  min-width: 250px;
  max-width: 45%;
  border-left: #abd5ff39 1px solid;
}

/* bootcamp */

.bootcamp-title {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 2%;
  min-width: 100%;
  padding-bottom: 3%;
  text-align: center;
  text-shadow: 1px 2px #000000;
  border-top: #abd5ffc5 1px solid;
  /* border-bottom: #abd5ff39 2px solid; */
  border-radius: 0 10px 0 10px;
}

.bootcamp-title h2 {
  /* background-color: #5d90c310; */
  background: linear-gradient(0deg, #00000039 0%, #5d90c310 100%);

  border-bottom: #5d90c3 1px solid;
  border-top: #5d90c3 1px solid;
  border-radius: 2% 2% 2% 2%;
  min-width: 50%;
  max-width: 50%;
  margin-bottom: 0;
  padding: 2%;
}

.bootcamp-title h3 {
  min-width: 90%;
  max-width: 90%;
  padding: 2%;
  margin-bottom: 0;
}

.bootcamp-subtitle {
  min-width: 100%;
  padding-left: 2%;
  padding-right: 2%;
}

.bootcamp-subtitle h3 {
  /* text-decoration: underline; */
  min-width: 100%;
  margin-top: 4%;
  padding: 2%;
  border-left: #5d90c3 2px solid;
  border-top: #5d90c3 1px solid;
  border-radius: 2% 0 0 0;
}

.bootcamp-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
}

.bootcamp-description {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  padding-bottom: 2%;
}

.bootcamp-description .bootcamp-description-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-width: 250px;
  max-width: 50%;
  padding: 2%;
  padding-right: 0;
}

.bootcamp-description .bootcamp-description-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  margin-left: 2%;
  /* padding-left: 2%; */
  padding-right: 2%;
  min-width: 250px;
  max-width: 45%;
}

.bootcamp-description-bottom {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  margin-top: 0;
  padding-left: 2%;
  padding-right: 2%;
}

/* RESUME */
/* RESUME */
.resume-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 5%;
  padding-top: 0;
  margin: auto;
  min-height: 75vh;
  width: 90%;
  background: linear-gradient(0deg, transparent 0%, #ffffff20 100%);
  border-top: #abd5ffc5 1px solid;
  border-left: #abd5ffc5 1px solid;
}

.resume-section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  padding: 5%;
  min-width: 40%;
  max-width: 50%;
}

.resume-section1 h3 {
  min-width: 100%;
  max-width: 100%;
  text-align: center;
}

.resume-section2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5%;
  min-width: 40%;
  max-width: 50%;
  height: auto;
}

/* .resume-section2 h3 {
  width: 100%;
  margin: 0;
  padding: 0;
  color: #f0ffff;
} */

.resume-section2 h2 {
  margin-bottom: 0%;
  padding-left: 2%;
  padding-right: 2%;
  border-left: #5d90c3 2px solid;
}

.code-block {
  /* border-left: #abd5ff39 2px solid; */
  /* margin-top: 4%; */
  padding: 2%;
  padding-top: 5%;
  min-width: 45%;
  max-width: 50%;
}

.code-block h2 {
  margin-bottom: 0%;
  margin-left: 3%;
  padding-left: 2%;
  border-left: #5d90c3 2px solid;
}

.code-block aside {
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #000000b0;
  border: #abd5ff39 1px solid;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 2%;
  padding: 3%;
  padding-left: 5%;
  min-width: 100%;
  max-width: fit-content;
}

.commentedOut {
  color: #778ca0;
}

.code-block ul {
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
}

.code-indent1 {
  padding-left: 0%;
  min-width: 100%;
}

.code-indent2 {
  padding-left: 20%;
  min-width: 100%;
}

.code-indent3 {
  padding-left: 25%;
  min-width: 100%;
}
/* .code-block ul {
  padding-left: 40%;
} */

/* .code-block ul li {
  padding-left: 15%;
} */

/* MEDIA QUERY - SMALL SCREEN */
/* MEDIA QUERY - SMALL SCREEN */
/* MEDIA QUERY - SMALL SCREEN */
/* MEDIA QUERY - SMALL SCREEN */
/* MEDIA QUERY - SMALL SCREEN */

@media screen and (max-width: 877px) {
  /* about */
  .section-container {
    justify-content: flex-start;
    min-height: 75vh;
  }

  .section-title {
    min-width: 100%;
    margin-bottom: 0;
  }

  .section-content {
    margin: 0%;
    padding: 3%;
    min-width: 100%;
    border-top: 1px solid #abd5ffc5;
    border-left: #abd5ffc5 1px solid;
    background: linear-gradient(0deg, transparent 0%, #ffffff20 100%);
  }

  /* projects */

  .projects-container {
    flex-direction: column;
    align-items: flex-end;
  }

  .projects-content {
    min-width: 100%;
    margin: 0%;
    padding: 0%;
  }

  .projects-aside {
    margin-bottom: 0;
  }

  .projects-title {
    margin: 0%;
    padding: 0%;
    margin-right: 2%;
    padding-right: 2%;
  }

  .projects-title h2 {
    margin-bottom: 0;
  }

  .projects-title p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .projects-title h4 {
    margin-top: 0;
  }

  .freelance-description {
    justify-content: flex-start;
  }

  .freelance-description span {
    text-align: center;
    min-width: 250px;
    max-width: 100%;
    padding-right: 0%;
  }

  .freelance-description ul {
    list-style-type: none;
    text-align: start;
    justify-self: flex-start;
    padding-left: 3%;
    min-width: 250px;
    max-width: 100%;
  }

  .bootcamp-description .bootcamp-description-left {
    display: flex;
    /* flex-direction: row; */
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    min-width: 100%;
    max-width: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-right: 2%;
  }

  .bootcamp-description .bootcamp-description-right {
    text-align: start;
    margin-left: 3%;
    padding-left: 2%;
    padding-right: 2%;
    min-width: 100%;
    max-width: 100%;
  }

  .bootcamp-description .bootcamp-description-right h3 {
    border-left: #abd5ff39 1px solid;
    padding-left: 2%;
  }

  /* resume */
  .resume-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .resume-section1 {
    min-width: 100%;
  }

  .resume-section2 {
    min-width: 100%;
    border-right: transparent;
    /* padding: 8%; */
  }

  .code-block {
    border-top: 1px solid #abd5ff39;
    padding-top: 5%;
    min-width: 100%;
    border-left: transparent;
  }
}
